import { css } from 'styled-components'

// react-css-transition-replace
// https://github.com/marnusw/react-css-transition-replace/blob/master/packages/demo/src/transitions.css
export const ReactAnimateReplaceStyles = css`
  .cross-fade-leave {
    opacity: 1;
  }
  .cross-fade-leave.cross-fade-leave-active {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .cross-fade-enter {
    opacity: 0;
  }
  .cross-fade-enter.cross-fade-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  .cross-fade-height {
    transition: height 0.5s ease-in-out;
  }

  /* Fade out, then fade in transition */

  .fade-wait-leave,
  .fade-wait-delay-leave {
    opacity: 1;
  }
  .fade-wait-leave.fade-wait-leave-active,
  .fade-wait-delay-leave.fade-wait-delay-leave-active {
    opacity: 0;
    transition: opacity 0.4s ease-in;
  }

  .fade-wait-enter,
  .fade-wait-delay-enter {
    opacity: 0;
  }
  .fade-wait-enter.fade-wait-enter-active,
  .fade-wait-delay-enter.fade-wait-delay-enter-active {
    opacity: 1;
    /* Delay the enter animation until the leave completes */
    transition: opacity 0.4s ease-in ${1.4 - 0.8}s;
  }

  .fade-wait-height {
    transition: height 0.8s ease-in-out;
  }
  .fade-wait-delay-height {
    /* Delay the height animation until the leave completes */
    transition: height 0.4s ease-in-out 0.4s;
  }

  /* Carousel-like transition */

  .carousel-swap-leave {
    transition: transform 1s ease-in-out;
    transform: translate(0, 0);
  }
  .carousel-swap-leave-active {
    transform: translate(-100%, 0);
  }

  .carousel-swap-enter {
    transition: transform 1s ease-in-out;
    transform: translate(100%, 0);
  }
  .carousel-swap-enter-active {
    transform: translate(0, 0);
  }

  .carousel-swap-height {
    transition: height 1s ease-in-out;
  }

  /* Roll-up transition */

  .roll-up-leave {
    transform: translate(0, 0);
    opacity: 1;
  }
  .roll-up-leave.roll-up-leave-active {
    transform: translate(-100%, 0);
    opacity: 0;
    transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  }

  .roll-up-enter {
    transform: translate(-100%, 0);
    opacity: 0;
  }
  .roll-up-enter.roll-up-enter-active {
    transform: translate(0, 0);
    opacity: 1;
    transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  }

  .roll-up-height {
    transition: height 0.8s ease-in-out;
  }

  /* Fade with width transition */

  .fade-fast-leave {
    opacity: 1;
  }
  .fade-fast-leave.fade-fast-leave-active {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .fade-fast-enter {
    opacity: 0;
  }
  .fade-fast-enter.fade-fast-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .fade-fast-height {
    transition: height 0.5s ease-in-out, width 0.5s ease-in-out;
  }
`
